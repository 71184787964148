
import vacaturesCount from '~/graphql/queries/vacaturesCount.gql';

export default {
  apollo: {
    vacaturesCount: {
      query: vacaturesCount,
    },
  },
  data: () => ({
    vacaturesCount: null,
    open: false,
    userNavigation: [
      {
        name: 'Your profile',
        route: 'auth-profiel',
      },
      {
        name: 'Sign out',
        logout: true,
      },
    ],
  }),
  computed: {
    mainMenu() {
      return this.$store.state.menuMain;
    },
  },
  methods: {
    async logout() {
      this.open = false;
      await this.$auth.logout();
      await this.$apolloProvider.defaultClient.cache.reset();
      await this.$router.push(this.localePath({name: 'home'}));
    },
  },
};
