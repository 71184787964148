export const BaseButton = () => import('../../components/base/BaseButton.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const FileUpload = () => import('../../components/base/FileUpload.vue' /* webpackChunkName: "components/file-upload" */).then(c => wrapFunctional(c.default || c))
export const FilterDropdown = () => import('../../components/base/FilterDropdown.vue' /* webpackChunkName: "components/filter-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Hero = () => import('../../components/base/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/base/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/base/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/base/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const RemoveButton = () => import('../../components/base/RemoveButton.vue' /* webpackChunkName: "components/remove-button" */).then(c => wrapFunctional(c.default || c))
export const ShareLinks = () => import('../../components/base/ShareLinks.vue' /* webpackChunkName: "components/share-links" */).then(c => wrapFunctional(c.default || c))
export const NavigationAzureLogin = () => import('../../components/base/navigation/AzureLogin.vue' /* webpackChunkName: "components/navigation-azure-login" */).then(c => wrapFunctional(c.default || c))
export const NavigationBackButton = () => import('../../components/base/navigation/BackButton.vue' /* webpackChunkName: "components/navigation-back-button" */).then(c => wrapFunctional(c.default || c))
export const User = () => import('../../components/User.vue' /* webpackChunkName: "components/user" */).then(c => wrapFunctional(c.default || c))
export const FormulateButton = () => import('../../components/formulate/Button.vue' /* webpackChunkName: "components/formulate-button" */).then(c => wrapFunctional(c.default || c))
export const FormulateCalendar = () => import('../../components/formulate/Calendar.vue' /* webpackChunkName: "components/formulate-calendar" */).then(c => wrapFunctional(c.default || c))
export const FormulateCheckboxNumberSuffix = () => import('../../components/formulate/CheckboxNumberSuffix.vue' /* webpackChunkName: "components/formulate-checkbox-number-suffix" */).then(c => wrapFunctional(c.default || c))
export const FormulateDate = () => import('../../components/formulate/Date.vue' /* webpackChunkName: "components/formulate-date" */).then(c => wrapFunctional(c.default || c))
export const FormulateToggle = () => import('../../components/formulate/Toggle.vue' /* webpackChunkName: "components/formulate-toggle" */).then(c => wrapFunctional(c.default || c))
export const IconsBarchart = () => import('../../components/icons/Barchart.vue' /* webpackChunkName: "components/icons-barchart" */).then(c => wrapFunctional(c.default || c))
export const IconsCase = () => import('../../components/icons/Case.vue' /* webpackChunkName: "components/icons-case" */).then(c => wrapFunctional(c.default || c))
export const IconsClock = () => import('../../components/icons/Clock.vue' /* webpackChunkName: "components/icons-clock" */).then(c => wrapFunctional(c.default || c))
export const IconsGlobe = () => import('../../components/icons/Globe.vue' /* webpackChunkName: "components/icons-globe" */).then(c => wrapFunctional(c.default || c))
export const IconsHat = () => import('../../components/icons/Hat.vue' /* webpackChunkName: "components/icons-hat" */).then(c => wrapFunctional(c.default || c))
export const IconsMail = () => import('../../components/icons/Mail.vue' /* webpackChunkName: "components/icons-mail" */).then(c => wrapFunctional(c.default || c))
export const IconsMap = () => import('../../components/icons/Map.vue' /* webpackChunkName: "components/icons-map" */).then(c => wrapFunctional(c.default || c))
export const IconsPhone = () => import('../../components/icons/Phone.vue' /* webpackChunkName: "components/icons-phone" */).then(c => wrapFunctional(c.default || c))
export const IconsUser = () => import('../../components/icons/User.vue' /* webpackChunkName: "components/icons-user" */).then(c => wrapFunctional(c.default || c))
export const LayoutsSideView = () => import('../../components/layouts/SideView.vue' /* webpackChunkName: "components/layouts-side-view" */).then(c => wrapFunctional(c.default || c))
export const ModalsEnableTwoFactorModal = () => import('../../components/modals/EnableTwoFactorModal.vue' /* webpackChunkName: "components/modals-enable-two-factor-modal" */).then(c => wrapFunctional(c.default || c))
export const VacatureBar = () => import('../../components/vacature/Bar.vue' /* webpackChunkName: "components/vacature-bar" */).then(c => wrapFunctional(c.default || c))
export const VacatureCard = () => import('../../components/vacature/Card.vue' /* webpackChunkName: "components/vacature-card" */).then(c => wrapFunctional(c.default || c))
export const VacatureEditForm = () => import('../../components/vacature/EditForm.vue' /* webpackChunkName: "components/vacature-edit-form" */).then(c => wrapFunctional(c.default || c))
export const VacatureFilters = () => import('../../components/vacature/Filters.vue' /* webpackChunkName: "components/vacature-filters" */).then(c => wrapFunctional(c.default || c))
export const VacatureInfoCard = () => import('../../components/vacature/InfoCard.vue' /* webpackChunkName: "components/vacature-info-card" */).then(c => wrapFunctional(c.default || c))
export const VacatureLoader = () => import('../../components/vacature/Loader.vue' /* webpackChunkName: "components/vacature-loader" */).then(c => wrapFunctional(c.default || c))
export const IconsActionsAccept = () => import('../../components/icons/actions/Accept.vue' /* webpackChunkName: "components/icons-actions-accept" */).then(c => wrapFunctional(c.default || c))
export const IconsActionsArrow = () => import('../../components/icons/actions/Arrow.vue' /* webpackChunkName: "components/icons-actions-arrow" */).then(c => wrapFunctional(c.default || c))
export const IconsActionsCaret = () => import('../../components/icons/actions/Caret.vue' /* webpackChunkName: "components/icons-actions-caret" */).then(c => wrapFunctional(c.default || c))
export const IconsActionsClose = () => import('../../components/icons/actions/Close.vue' /* webpackChunkName: "components/icons-actions-close" */).then(c => wrapFunctional(c.default || c))
export const IconsActionsEdit = () => import('../../components/icons/actions/Edit.vue' /* webpackChunkName: "components/icons-actions-edit" */).then(c => wrapFunctional(c.default || c))
export const IconsActionsFilter = () => import('../../components/icons/actions/Filter.vue' /* webpackChunkName: "components/icons-actions-filter" */).then(c => wrapFunctional(c.default || c))
export const IconsActionsMenu = () => import('../../components/icons/actions/Menu.vue' /* webpackChunkName: "components/icons-actions-menu" */).then(c => wrapFunctional(c.default || c))
export const IconsActionsReset = () => import('../../components/icons/actions/Reset.vue' /* webpackChunkName: "components/icons-actions-reset" */).then(c => wrapFunctional(c.default || c))
export const IconsActionsSearch = () => import('../../components/icons/actions/Search.vue' /* webpackChunkName: "components/icons-actions-search" */).then(c => wrapFunctional(c.default || c))
export const IconsActionsTrash = () => import('../../components/icons/actions/Trash.vue' /* webpackChunkName: "components/icons-actions-trash" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialFacebook = () => import('../../components/icons/social/Facebook.vue' /* webpackChunkName: "components/icons-social-facebook" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialLinkedin = () => import('../../components/icons/social/Linkedin.vue' /* webpackChunkName: "components/icons-social-linkedin" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialTwitter = () => import('../../components/icons/social/Twitter.vue' /* webpackChunkName: "components/icons-social-twitter" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
