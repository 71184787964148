import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _38a5516c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _213845b8 = () => interopDefault(import('../pages/mijn-vacatures/index.vue' /* webpackChunkName: "pages/mijn-vacatures/index" */))
const _274ee851 = () => interopDefault(import('../pages/vacatures/index.vue' /* webpackChunkName: "pages/vacatures/index" */))
const _57fc831a = () => interopDefault(import('../pages/auth/actie-vereist.vue' /* webpackChunkName: "pages/auth/actie-vereist" */))
const _40784244 = () => interopDefault(import('../pages/auth/callback.vue' /* webpackChunkName: "pages/auth/callback" */))
const _036bbef0 = () => interopDefault(import('../pages/auth/inloggen.vue' /* webpackChunkName: "pages/auth/inloggen" */))
const _0af5134a = () => interopDefault(import('../pages/auth/redirect.vue' /* webpackChunkName: "pages/auth/redirect" */))
const _5163e08e = () => interopDefault(import('../pages/auth/wachtwoord-vergeten/index.vue' /* webpackChunkName: "pages/auth/wachtwoord-vergeten/index" */))
const _1d79d57e = () => interopDefault(import('../pages/mijn-vacatures/nieuw.vue' /* webpackChunkName: "pages/mijn-vacatures/nieuw" */))
const _05977763 = () => interopDefault(import('../pages/templates/default.vue' /* webpackChunkName: "pages/templates/default" */))
const _0fdeb560 = () => interopDefault(import('../pages/auth/wachtwoord-vergeten/token.vue' /* webpackChunkName: "pages/auth/wachtwoord-vergeten/token" */))
const _24a58848 = () => interopDefault(import('../pages/mijn-vacatures/_slug.vue' /* webpackChunkName: "pages/mijn-vacatures/_slug" */))
const _25984709 = () => interopDefault(import('../pages/vacatures/_slug.vue' /* webpackChunkName: "pages/vacatures/_slug" */))
const _6d379abc = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _38a5516c,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/mijn-vacatures",
    component: _213845b8,
    pathToRegexpOptions: {"strict":true},
    name: "mijn-vacatures___nl"
  }, {
    path: "/vacatures",
    component: _274ee851,
    pathToRegexpOptions: {"strict":true},
    name: "vacatures___nl"
  }, {
    path: "/auth/actie-vereist",
    component: _57fc831a,
    pathToRegexpOptions: {"strict":true},
    name: "auth-actie-vereist___nl"
  }, {
    path: "/auth/callback",
    component: _40784244,
    pathToRegexpOptions: {"strict":true},
    name: "auth-callback___nl"
  }, {
    path: "/auth/inloggen",
    component: _036bbef0,
    pathToRegexpOptions: {"strict":true},
    name: "auth-inloggen___nl"
  }, {
    path: "/auth/redirect",
    component: _0af5134a,
    pathToRegexpOptions: {"strict":true},
    name: "auth-redirect___nl"
  }, {
    path: "/auth/wachtwoord-vergeten",
    component: _5163e08e,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten___nl"
  }, {
    path: "/en/mijn-vacatures",
    component: _213845b8,
    pathToRegexpOptions: {"strict":true},
    name: "mijn-vacatures___en"
  }, {
    path: "/en/vacatures",
    component: _274ee851,
    pathToRegexpOptions: {"strict":true},
    name: "vacatures___en"
  }, {
    path: "/mijn-vacatures/nieuw",
    component: _1d79d57e,
    pathToRegexpOptions: {"strict":true},
    name: "mijn-vacatures-nieuw___nl"
  }, {
    path: "/templates/default",
    component: _05977763,
    pathToRegexpOptions: {"strict":true},
    name: "templates-default___nl"
  }, {
    path: "/auth/wachtwoord-vergeten/token",
    component: _0fdeb560,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten-token___nl"
  }, {
    path: "/en/auth/actie-vereist",
    component: _57fc831a,
    pathToRegexpOptions: {"strict":true},
    name: "auth-actie-vereist___en"
  }, {
    path: "/en/auth/callback",
    component: _40784244,
    pathToRegexpOptions: {"strict":true},
    name: "auth-callback___en"
  }, {
    path: "/en/auth/inloggen",
    component: _036bbef0,
    pathToRegexpOptions: {"strict":true},
    name: "auth-inloggen___en"
  }, {
    path: "/en/auth/redirect",
    component: _0af5134a,
    pathToRegexpOptions: {"strict":true},
    name: "auth-redirect___en"
  }, {
    path: "/en/auth/wachtwoord-vergeten",
    component: _5163e08e,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten___en"
  }, {
    path: "/en/mijn-vacatures/nieuw",
    component: _1d79d57e,
    pathToRegexpOptions: {"strict":true},
    name: "mijn-vacatures-nieuw___en"
  }, {
    path: "/en/templates/default",
    component: _05977763,
    pathToRegexpOptions: {"strict":true},
    name: "templates-default___en"
  }, {
    path: "/en/auth/wachtwoord-vergeten/token",
    component: _0fdeb560,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten-token___en"
  }, {
    path: "/en/mijn-vacatures/:slug?",
    component: _24a58848,
    pathToRegexpOptions: {"strict":true},
    name: "mijn-vacatures-slug___en"
  }, {
    path: "/en/vacatures/:slug",
    component: _25984709,
    pathToRegexpOptions: {"strict":true},
    name: "vacatures-slug___en"
  }, {
    path: "/mijn-vacatures/:slug?",
    component: _24a58848,
    pathToRegexpOptions: {"strict":true},
    name: "mijn-vacatures-slug___nl"
  }, {
    path: "/vacatures/:slug",
    component: _25984709,
    pathToRegexpOptions: {"strict":true},
    name: "vacatures-slug___nl"
  }, {
    path: "/en/*",
    component: _6d379abc,
    pathToRegexpOptions: {"strict":true},
    name: "*___en"
  }, {
    path: "/",
    component: _38a5516c,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl"
  }, {
    path: "/*",
    component: _6d379abc,
    pathToRegexpOptions: {"strict":true},
    name: "*___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
