import Vue from 'vue';
import {nl} from '@braid/vue-formulate-i18n';
import errorHandler from '~/libs/form-error-handler';

import Button from '~/components/formulate/Button';
import Date from '~/components/formulate/Date';
import Datepicker from '~/components/formulate/Calendar';
import Toggle from '~/components/formulate/Toggle';
import CheckboxNumberSuffix from '~/components/formulate/CheckboxNumberSuffix';

Vue.component('Button', Button);
Vue.component('Date', Date);
Vue.component('Datepicker', Datepicker);
Vue.component('Toggle', Toggle);

const DEFAULT_CLASSES = 'block w-full py-3 rounded-md border border-dark-lighter-3 text-dark text-base hover:border-dark-lighter-2 focus:border-primary-lighter-1 focus:outline-none placeholder:text-dark-lighter-1 appearance-none';

export default {
  validationNameStrategy: ['validationName', 'label', 'name', 'type'],
  library: {
    button: {
      classification: 'button',
      component: Button,
    },
    submit: {
      classification: 'button',
      component: Button,
    },
    date: {
      classification: 'date',
      component: Date,
    },
    calendar: {
      classification: 'calendar',
      component: Datepicker,
      slotProps: {
        component: ['now'],
      },
    },
    toggle: {
      classification: 'checkbox',
      component: Toggle,
    },
    checkbox: {
      slotComponents: {
        label: CheckboxNumberSuffix,
      },
    },
  },
  plugins: [nl],
  classes: {
    input(context) {
      switch (context.classification) {
        case 'button':
          return 'w-full btn btn-primary';
        case 'box':
          return 'text-primary accent-green border-dark-lighter-3 overflow-hidden h-5 w-5 focus:ring-dark-lighter-2 focus:accent-green';
        case 'group':
          return '';
        case 'file':
          return 'appearance-none opacity-0 cursor-pointer absolute inset-0 w-full h-full z-10';
        case 'textarea':
          return `${DEFAULT_CLASSES} h-56 p-2.5`;
        default:
          return `${DEFAULT_CLASSES} ${(context.attrs['has-prefix'] !== undefined) ? 'pl-11 pr-3' : 'px-3'}`;
      }
    },
    element({classification}) {
      switch (classification) {
        case 'box':
          return 'flex items-center px-1';
        default:
          return 'w-full relative';
      }
    },
    outer(context) {
      if (context.isGrouped) {
        return 'mb-3';
      } else {
        switch (context.classification) {
          case 'button':
            return '';
          case 'radio':
            return '';
          default:
            return 'mb-9 relative';
        }
      }
    },
    wrapper(context) {
      switch (context.classification) {
        case 'button':
          return '';
        case 'box':
          return 'w-full flex justify-start';
        default:
          return '';
      }
    },
    label(context) {
      switch (context.classification) {
        case 'box':
          return 'text-base text-dark-lighter-1 flex-grow ml-1 checkbox-label filter-option-label';
        default:
          return 'block absolute -top-1.5 -mt-2 ml-2 px-1 bg-white z-10 text-sm text-dark-lighter-1 font-bold';
      }
    },
    /* decorator: ({hasValue}) => {
      let base = 'border rounded border-gray focus:border-primary-light inline-block w-4 h-4 mr-2';
      if (hasValue) {
        base += ' bg-blue-light';
      }
      return base;
    }, */
    help: 'text-xs mb-1 text-gray-dark',
    error: 'text-error text-xs mb-1 mt-0.5',
    uploadArea: 'upload-area',
    file: ' appearance-none rounded border p-3 block flex relative items-center justify-center',
    files: 'hidden',
    uploadAreaMask({attrs: {loading}}) {
      const base = 'absolute pointer-events-none flex justify-center items-center inset-0 border-2 border-dashed border-gray rounded upload-placeholder';

      if (loading) {
        return `${base} loading`;
      } else {
        return base;
      }
    },
  },
  errorHandler,
};
