import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=0a89e25f&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsSocialTwitter: require('/data/www/vacaturebank.kngf.nl/kngf/nuxt/components/icons/social/Twitter.vue').default,IconsSocialLinkedin: require('/data/www/vacaturebank.kngf.nl/kngf/nuxt/components/icons/social/Linkedin.vue').default})
