
export default {
  computed: {
    crumbs() {
      if (this.$route.fullPath === '/') {
        return [];
      }

      const fullPath = this.$route.fullPath;
      const params = fullPath.startsWith('/') ? fullPath.substring(1).split('/') : fullPath.split('/');
      const crumbs = [{fullPath: '/', label: 'home'}];

      let path = '';

      params.forEach((param) => {
        path = `${path}/${param}`;
        const match = this.$router.match(path);

        if (match.name !== null) {
          const split = path.split('/');
          const label = (split.pop() || split.pop())
            .replace(/-/g, ' ')
            .toLowerCase();

          if (label === 'auth') {
            return;
          }

          crumbs.push({...match, label});
        }
      });

      crumbs.pop();

      return crumbs;
    },
  },
};
